

























































































































































import { Component, Vue } from 'vue-property-decorator'
import { callAppFunc_setNavColor } from '@/common/utils/bridge'

import { getRankAwardConfig } from '@/pages/videoGame/api'
@Component
export default class extends Vue {
  activeTab = 1

  rankList: any = []
  rankList2: any = []

  rankList3: any = []

  changeTab(val: number) {
    this.activeTab = val
  }
  initList(data: any, rankType: string) {
    let tmp = {
      start: 1,
      rankDisplayTime: data.list[0].rankDisplayTime,

      rankAwardCoin: data.list[0].rankAwardCoin,
      rankAwardAmount: data.list[0].rankAwardAmount,
      entryTipsTime: data.list[0].entryTipsTime,
      end: 0
    }
    let tmpList: any = []
    for (let i = 1; i < data.list.length; i++) {
      const curItem = data.list[i]
      if (
        tmp.rankDisplayTime == curItem.rankDisplayTime &&
        tmp.rankAwardCoin == curItem.rankAwardCoin &&
        tmp.rankAwardAmount == curItem.rankAwardAmount &&
        tmp.entryTipsTime == curItem.entryTipsTime
      ) {
        tmp.end = curItem.rankNum
      } else {
        tmpList.push(tmp)
        tmp = {
          start: curItem.rankNum,
          rankDisplayTime: curItem.rankDisplayTime,

          rankAwardCoin: curItem.rankAwardCoin,
          rankAwardAmount: curItem.rankAwardAmount,
          entryTipsTime: curItem.entryTipsTime,
          end: 0
        }
      }
      if (i == data.list.length - 1) {
        tmpList.push(tmp)
      }
    }
    tmpList = tmpList.filter((item: any) => {
      return (
        item.rankDisplayTime ||
        item.rankAwardCoin ||
        item.rankAwardAmount ||
        item.entryTipsTime
      )
    })
    if (rankType == 'day') {
      this.rankList = tmpList
    } else if (rankType == 'week') {
      this.rankList2 = tmpList
    } else {
      this.rankList3 = tmpList
    }
  }
  async getData(rankType: string) {
    const { data } = await getRankAwardConfig({ rankType })
    data.list.sort((itemA: any, itemB: any) => {
      return itemA.rankNum - itemB.rankNum
    })
    this.initList(data, rankType)
  }
  mounted() {
    callAppFunc_setNavColor({ navColor: '#F9E571' })
    this.getData('day')
    this.getData('week')

    this.getData('month')
  }
  beforeDestroy() {
    callAppFunc_setNavColor({ navColor: '#ffffff' })
  }
}
